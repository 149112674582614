
import { cardView, userDatas } from "@app/hoc/cache";
import { isLocal } from "../components/Exam/containers/GameFullScreen";
import { isMobile, deviceType, isAndroid } from "react-device-detect";



export const taskSize = [0, 0, 0, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2];
export const ptaskSize = [0, 0, 0, 4, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5];
//export const statusStyle = [['예정','#C50000','#FFF8EB'],['진행','#1A8BAE','#D6FEF3'],['완료','#556AEB','#EBEFFF']];
export const statusStyle = [['예정', '#C50000', '#FFF8EB'], ['요청', '#C50000', '#FFF8EB'], ['완료', '#556AEB', '#EBEFFF']];

export const getMonth = (birthdate: any) => {
  const now = new Date();
  const date = new Date(birthdate);

  let year = now.getFullYear() - date.getFullYear();
  let month = now.getMonth() - date.getMonth();
  let day = now.getDate() - date.getDate();
  if (day < 0) { month--; day += 30; }
  if (month < 0) { year--; month += 12; }
  return year * 12 + month;
  /*
      const now = new Date();
      const date = new Date(birthdate); 
    
      const millisecondsDiff = now.getTime() - date.getTime();
      const dday = millisecondsDiff/(1000*60*60*24)|0;
      return dday/30.417|0;
  */
}

export const getFormatedAge = (month: number, type: number) => {
  if (type === 1 && month > 38) return '';

  if (month < 38) return `${month}개월`;
  const year = month / 12 | 0;
  const m = month - year * 12;
  return `${year}세 ${m}개월`;
}

export const getExamMonth = (birthdate: any, epWeek: any, epDay: any) => {

  const now = new Date();
  const date = new Date(birthdate);

  let year = now.getFullYear() - date.getFullYear();
  let month = now.getMonth() - date.getMonth();
  let day = now.getDate() - date.getDate();
  if (day < 0) { month--; day += 30; }
  if (month < 0) { year--; month += 12; }

  let ep = 0;
  if (epWeek < 37) {
    ep = (39 - epWeek) * 7 + (7 - epDay);
    const mep = ep / 30 | 0;
    const dep = ep - mep * 30;
    day = day - dep;
    if (day < 0) { month--; day += 30; }
    month = month - mep;
  }
  return year * 12 + month;
}

export const getExamMonthFromReserved = (birthdate: any, epWeek: any, epDay: any, rDate:any) => {

  const now = new Date(rDate);
  const date = new Date(birthdate);

  let year = now.getFullYear() - date.getFullYear();
  let month = now.getMonth() - date.getMonth();
  let day = now.getDate() - date.getDate();
  if (day < 0) { month--; day += 30; }
  if (month < 0) { year--; month += 12; }

  let ep = 0;
  if (epWeek < 37 && (year*12+month)<24) {
    let dep = 40*7 - (epWeek*7 + epDay);
    const mep = dep / 28 | 0;
    dep = dep - mep * 28;
    day = day - dep;
    if( day < 0 ) { month--; day += 30; }
    month = month - mep;
    if( month < 0 ) { year--; month += 12; }
  }
  //console.log( (year * 12 + month) + "개월 " + day+ "일")
  return year * 12 + month;
}

export const getAgeExamMonth = (birthdate: any, epWeek: any, epDay: any) => {
  let ep = 0;
  if (epWeek < 37)
    ep = (39 - epWeek) * 7 + (7 - epDay);

  const now = new Date();
  const date = new Date(birthdate);

  const millisecondsDiff = now.getTime() - date.getTime();
  const dday = millisecondsDiff / (1000 * 60 * 60 * 24) | 0;

  const diffDays = dday - ep;

  return diffDays / 30.417 | 0;

  /*  
    const diffyear = dday/365|0;
    const diffmonth = (dday - diffyear*365)/30|0;
    const diffday = dday - diffyear*365 - diffmonth*30;
  */
}

//export const regions = {"01":"강남구","02":"영등포구"};

type tRegions = {
  [key: string]: string
}

export const regions: tRegions = {
  "A": "강남구",
  "B": "강동구",
  "C": "강북구",
  "D": "강서구",
  "E": "관악구",
  "F": "광진구",
  "G": "구로구",
  "H": "금천구",
  "I": "노원구",
  "J": "도봉구",
  "K": "동대문구",
  "L": "동작구",
  "M": "마포구",
  "N": "서대문구",
  "P": "성동구",
  "Q": "성북구",
  "R": "송파구",
  "S": "양천구",
  "T": "영등포구",
  "U": "용산구",
  "V": "은평구",
  "W": "종로구",
  "X": "중구",
  "Y": "중랑구",
  "Z": "서초구",
};

export const pGroup: tRegions = {
  "A": "657d63b7abc80048091bbbbb", //"강남구",
  "B": "657d68711997bc7e560191a6", //"강동구",
  "C": "657d68a66933bea0942a86f6", //"강북구",
  "D": "657d68bc1997bc7e560191b6", //"강서구",
  "E": "657d68d06933bea0942a8706", //"관악구",

  "F": "657d68e61997bc7e560191c6", //"광진구",
  "G": "657d68f71997bc7e560191d6", //"구로구",
  "H": "657d690d9dc2e168262257b6", //"금천구",
  "I": "657d69229dc2e168262257b9", //"노원구",
  "J": "657d69389dc2e168262257c5", //"도봉구",

  "K": "657d69501997bc7e56019200", //"동대문구",
  "L": "657d69649dc2e168262257cc", //"동작구",
  "M": "657d69761997bc7e56019210", //"마포구",
  "N": "657d69876933bea0942a8709", //"서대문구",
  "P": "657d699a6933bea0942a8714", //"성동구",

  "Q": "657d69af1997bc7e56019224", //"성북구",
  "R": "657d69c16933bea0942a8729", //"송파구",
  "S": "657d69dd1997bc7e5601922b", //"양천구",
  "T": "657d20cc4bc2cc72cb60ea81", //"영등포구",
  "U": "657d69fc9dc2e168262257ed", //"용산구",

  "V": "657d6a0b1997bc7e5601923b", //"은평구",
  "W": "657d6a1b1997bc7e56019247", //"종로구",
  "X": "657d6a291997bc7e56019253", //"중구",
  "Y": "657d6a409dc2e16826225801", //"중랑구",
  "Z": "657d6a559dc2e1682622580d", //"서초구",
};

export const GOOGLE_REDIRECT_URL = `${window.location.origin}/google/callback`;
export const KAKAO_REDIRECT_URL = `${window.location.origin}/kakao/callback`;
export const NAVER_REDIRECT_URL = `${window.location.origin}/naver/callback`;

export const setSize = () => {
  //-------------
  const innerWidth = window.innerWidth;
  const innerHeight = ((window.innerHeight > 800) && isAndroid && (window.innerHeight > innerWidth)) ? 601 : window.innerHeight;  // === 816
  //console.log("WINDOW 2(wxh): "+window.innerWidth+" x "+window.innerHeight);
  let hoffset = 0;
  let voffset = 0;

  //let isMobile = (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
  if (isMobile !== true) {
    hoffset = 0;
    voffset = 0;
  }
  else {
    //console.log("mobile device");
    hoffset = 0;
    voffset = 0;
  }
  //after 221014----------------------------------------
  let mode = 1; // width 기준
  let ratio = 1.56; //1.618 golden ratio card 1.603/1.56, A4 1.414 / 0.64

  if (innerWidth > innerHeight)
    ratio = (innerHeight / innerWidth) - 0.05;


  let cWidth = innerWidth - innerWidth / 10;
  let cHeight = (innerWidth - hoffset) * ratio + voffset; //+100; fullscreen compenation

  if (localStorage.getItem('type') !== 'exam') { // height 기준
    cHeight = innerHeight - 70;
    cWidth = (cHeight - voffset) / ratio + hoffset - 60;
    if (cWidth + 30 > innerWidth) cWidth = innerWidth - 60;
    mode = 2;
  }
  /*      
  if( type !== 'exam' ){ // height 기준
    cHeight = window.innerHeight - 70;
    cWidth = (cHeight - voffset)/ratio + hoffset;
    mode = 2;
  }
  
  if( cHeight > window.innerHeight){ // height 기준
    cHeight = window.innerHeight - 100;
    cWidth = (cHeight - voffset)/ratio + hoffset;
    mode = 2;
  }
  */
  /* before 221014
  let mode = 1; // width 기준
  let ratio = 1.56; //1.618 golden ratio card 1.603/1.56, A4 1.414 
  if(window.innerWidth > window.innerHeight)
    ratio = 0.7;
  let cWidth = window.innerWidth - 20;
  let cHeight = (window.innerWidth - hoffset)*ratio+voffset;
  if( cHeight > window.innerHeight){ // height 기준
    cHeight = window.innerHeight - 100;
    cWidth = (cHeight - voffset)/ratio + hoffset;
    mode = 2;
  }
  */
  //console.log("CARD "+(mode==1?'width':'height')+"(wxh): "+cWidth+" x "+cHeight+" new");
  cHeight = (cHeight - 0) | 0;
  cWidth = (cWidth + 0) | 0;
  /*
  if( isMobile !== true ){
    hoffset = 160;
    voffset = 96;
  }
  else{
    //console.log("mobile device");
    hoffset = -10;
    voffset = -96;
  }
  
  let mode = 1; // width 기준
  let ratio = 2.6; //1.618; golden ratio
  let cWidth = window.innerWidth;
  let cHeight = (window.innerWidth - hoffset)*ratio+voffset;
  if( cHeight > window.innerHeight){ // height 기준
    cWidth = (window.innerHeight - voffset)/ratio + hoffset;
    cHeight = window.innerHeight;
    mode = 2;
  }

  //console.log("CARD "+(mode==1?'width':'height')+"(wxh): "+cWidth+" x "+cHeight+" new");
  cHeight = (cHeight - 100)|0;
  cWidth = (cWidth+10)|0;
  */
  //cWidth = window.innerWidth; //280;
  //cHeight = getHeight(); //531;
  /*
  if( cHeight === 746 ){
    cWidth = 280;
    cHeight = 531;
  }
  if( isAndroid && (cHeight > cWidth*2.5) ){
    cWidth = 280;
    cHeight = 531;
  }
  */

  ratio = (cHeight * 100 / cWidth) | 0;
  userDatas({ ...userDatas(), width: cWidth, height: cHeight, ratio: ratio });
  // eslint-disable-next-line no-restricted-globals 
  cardView({ ...cardView(), mobile: isMobile, width: cWidth, height: cHeight }); //, src: type === 'id'?1:0});
  console.log('App - resize (' + cWidth + 'x' + cHeight + ')');      //----------------------------------------
  return { height: cHeight, width: cWidth };
}

/*
export const taskSize = [0,0,0,4,4,4,4,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2];
export const statusStyle = [['예정','#C50000','#FFF8EB'],['진행','#1A8BAE','#D6FEF3'],['완료','#556AEB','#EBEFFF']];

export const getMonth = (birthdate:any) => {

    const now = new Date();
    const date = new Date(birthdate); 
  
    const millisecondsDiff = now.getTime() - date.getTime();
    const dday = millisecondsDiff/(1000*60*60*24)|0;
    return dday/30.417|0;
}

export const getExamMonth = (birthdate:any, epWeek:any, epDay:any) => {
  let ep = 0;
  if(epWeek < 37 )
      ep = (39 - epWeek)*7+(7-epDay);

  let now = new Date();
  let date = new Date(birthdate); 

  const millisecondsDiff = now.getTime() - date.getTime();
  const dday = millisecondsDiff/(1000*60*60*24)|0;

  const diffDays = dday - ep;

  return diffDays/30.417|0;
  
}

//export const regions = {"01":"강남구","02":"영등포구"};

export const regions : { [key: string]: any } = {
"A":"강남구",
"B":"강동구",
"C":"강북구",
"D":"강서구",
"E":"관악구",
"F":"광진구",
"G":"구로구",
"H":"금천구",
"I":"노원구",
"J":"도봉구",
"K":"동대문구",
"L":"동작구",
"M":"마포구",
"N":"서대문구",
"P":"성동구",
"Q":"성북구",
"R":"송파구",
"S":"양천구",
"T":"영등포구",
"U":"용산구",
"V":"은평구",
"W":"종로구",
"X":"중구",
"Y":"중랑구",
"Z":"서초구",
};
*/